import React from 'react';
import styled from 'styled-components';
import '@reach/dialog/styles.css';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import Container from '../components/Container';
import Text from '../components/Text';
import GalleryOverlay from '../components/Gallery';

import Media from '../helpers/mediaTemplates';
import config from '../config';

const Content = styled.div`
  min-height: calc(100vh - ${config.sizes.header}px);
  height: auto;
  width: 100%;
`;

const GalleryContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const GalleryItem = styled.button`
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;

  height: 300px;
  width: 300px;
  margin: 5px 0;

  ${Media.MOBILE`
    height: 175px;
    width: 175px;
  `}
`;

const Gallery = ({ data }) => {
  const [imageIndex, setImageIndex] = React.useState(undefined);

  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    const tempImages = data.allFile.edges.map(({ node }) => ({
      ...node.childImageSharp.fluid,
    }));
    setImages(tempImages);
  }, [data.allFile.length]);

  return (
    <>
      <Helmet>
        <title>Excel Fitness Club | Gallery</title>
      </Helmet>
      <GalleryOverlay
        showing={Boolean(typeof imageIndex === 'number')}
        close={() => setImageIndex(undefined)}
        image={images[imageIndex]}
        carousel={images}
      />
      <Container>
        <Content>
          <Text as="h1" size="x-large" weight="bold">
            Gallery
          </Text>
          <GalleryContent>
            {images.map((galeryImage, index) => (
              <GalleryItem
                key={index}
                type="button"
                onClick={() => setImageIndex(index)}
              >
                <Img
                  fluid={galeryImage}
                  style={{ height: '100%', width: '100%' }}
                />
              </GalleryItem>
            ))}
          </GalleryContent>
        </Content>
      </Container>
    </>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)/" }
        relativeDirectory: { eq: "gallery" }
      }
    ) {
      edges {
        node {
          id
          name
          absolutePath
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

Gallery.propTypes = {
  data: PropTypes.shape({
    allFile: {
      childImageSharp: {
        fluid: PropTypes.shape,
      },
    },
  }),
};

export default Gallery;
