import React from 'react';
import styled from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';

import config from '../../config';
import Media from '../../helpers/mediaTemplates';

import Icon from '../Icon';

const StyledDialogOverlay = styled(DialogOverlay)`
  z-index: ${config.zIndex.dialog};

  > [data-reach-dialog-content] {
    position: relative;
    width: 90vw;
    min-height: 50vh;
    ${Media.MOBILE`
      min-height: 20vh;
    `};
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ImageCarousel = styled.div`
  margin-top: 5px;
  height: 100px;
  width: 100%;
  background-color: red;

  > div {
    height: 100%;
    background-color: green;

    display: flex;
    flex-direction: row;

    overflow-y: auto;
    overflow-x: auto;

    div:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const CarouselItem = styled.div`
  flex-shrink: 0;
  height: 75px;
  width: 75px;
  background-color: purple;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${config.zIndex.dialog + 1};
  padding: 0;
  border: none;
  background-color: transparent;
`;

const GalleryOverlay = ({ showing, close, image, carousel = [] }) => {
  return showing ? (
    <StyledDialogOverlay
      onDismiss={close}
      style={{ background: 'hsla(0, 0%, 0%, 0.70)' }}
    >
      <DialogContent>
        <CloseButton type="button" onClick={close}>
          <Icon name="x" size={35} color="white" />
        </CloseButton>
        <Img
          fluid={image}
          style={{ height: '100%', width: '100%' }}
          objectFit="contain"
        />
        {/*
        {carousel.length && (
          <ImageCarousel>
            <div>
              {carousel.map((image, index) => (
                <CarouselItem key={index} />
              ))}
            </div>
          </ImageCarousel>
        )}
        */}
      </DialogContent>
    </StyledDialogOverlay>
  ) : null;
};

GalleryOverlay.propTypes = {
  showing: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  image: PropTypes.object,
  carousel: PropTypes.arrayOf(PropTypes.object),
};

export default GalleryOverlay;
